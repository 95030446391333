import React, { createContext, useContext, useState, ReactNode } from 'react';

interface Page {
    id: string;
    content: string;
    sequence: string;
    bookName: string;
    pageNo: string;
    bookId: string;
}

interface SelectedBook {
    id: string;
    name: string;
}

interface PageContextType {
    filteredPages: Page[];
    setFilteredPages: React.Dispatch<React.SetStateAction<Page[]>>;
    selectedBookName: SelectedBook[];
    setSelectedBookName: React.Dispatch<React.SetStateAction<SelectedBook[]>>;
    selectedAuthors: string[];
    setSelectedAuthors: React.Dispatch<React.SetStateAction<string[]>>;
    selectedCategories: string[];
    setSelectedCategories: React.Dispatch<React.SetStateAction<string[]>>;
    selectedBook: string;
    setSelectedBook: React.Dispatch<React.SetStateAction<string>>;
    sequenceNumber: string | number; // New state
    setSequenceNumber: React.Dispatch<React.SetStateAction<string | number>>;
}

const PageContext = createContext<PageContextType | undefined>(undefined);

interface PageProviderProps {
    children: ReactNode;
}

export const PageProvider: React.FC<PageProviderProps> = ({ children }) => {
    const [filteredPages, setFilteredPages] = useState<Page[]>([]);
    const [selectedBookName, setSelectedBookName] = useState<SelectedBook[]>([]);
    const [selectedAuthors, setSelectedAuthors] = useState<string[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [selectedBook, setSelectedBook] = useState<string>("");
    const [sequenceNumber, setSequenceNumber] = useState<string | number>('');


    return (
        <PageContext.Provider value={{
            filteredPages,
            setFilteredPages,
            selectedBookName,
            setSelectedBookName,
            selectedAuthors,
            setSelectedAuthors,
            selectedCategories,
            setSelectedCategories,
            selectedBook,
            setSelectedBook,
            sequenceNumber, 
            setSequenceNumber, 
        }}>
            {children}
        </PageContext.Provider>
    );
};

export const usePageContext = (): PageContextType => {
    const context = useContext(PageContext);
    if (!context) {
        throw new Error('usePageContext must be used within a PageProvider');
    }
    return context;
};
