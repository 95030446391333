import axios from "axios";
import Cookies from "universal-cookie";
const apiUrl = process.env.REACT_APP_API_URL + "/api";

const cookies = new Cookies();

export const AddpageApi = async (
  pageNo: string,
  sequence: number,
  contentWithHtml: string,
  content: string,
  bookId: string,
  type: string,
  format: string,
  fontType: string,
  renderType: string


) => {
  const token = cookies.get("authToken");

  const normalizeValue = (value: string) => (value === ""? null: value)

  const requestData = {
    pageNo,
    sequence,
    contentWithHtml,
    content,
    bookId,
    type:  normalizeValue(type),
    format:  normalizeValue(format),
    fontType:  normalizeValue(fontType),
    renderType:  normalizeValue(renderType)
  };

  console.log("Request Payload:", requestData);
  try {
    const response = await axios.post(
      `${apiUrl}/page/Create`,
      requestData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;

  } catch (error) {
    console.error("Error adding page:", error);
    throw error;
  }
};

export const FetchpageApi = async (selectedBook: string) => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.get(`${apiUrl}/page/GetAllpage`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }, params: {
        bookId: selectedBook
      }
    });
    if (response.data) {
      return response.data;
    } else {
      console.log("No data received.");
    }

    return [];
  } catch (error) {
    console.error("Error fetching pages:", error);
    throw error;
  }
};

export const EditPageApi = async (
  id: string | null,
  bookId: string | null,
  pageNo: string,
  sequence: number,
  contentWithHtml: string,
  content: string,
  type: string,
  format: string,
  fontType: string,
  renderType: string,
  status: number,
) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.post(
      `${apiUrl}/page/edit`,
      {
        id: id || undefined,
        bookId: bookId || undefined,
        pageNo: pageNo,
        sequence: Number(sequence),
        contentWithHtml: contentWithHtml.trim(),
        content: content,
        type: type,
        format: format,
        fontType: fontType,
        renderType: renderType, 
        status: status

      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error editing Pages:", error.response?.data);
      console.error("Error status:", error.response?.status);
    } else {
      console.error("Unexpected error:", error);
    }
    throw error;
  }
};



export const UpdatePageSequenceApi = async (
  id: string,
  sequence: number
): Promise<any> => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.post(
      `${apiUrl}/page/edit`,
      {
        id,
        sequence
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error updating page sequence:", error.response?.data);
      console.error("Error status:", error.response?.status);
    } else {
      console.error("Unexpected error:", error);
    }
    throw error;
  }
};

export const getPageByIdApi = async (id: string) => {
  const cookies = new Cookies();
  const token = cookies.get("authToken");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/page/getbyid`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getPagesByBookIdApi = async (bookId: string) => {
  const cookies = new Cookies();
  const token = cookies.get("authToken");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/page/getbybookid`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          bookId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const publishPagesApi = async (
  id: string,
  bookId: string,
  pageNo: string,
  pageSequence: string,
  pageContent: string,
  type: string | null,
  format: string | null,
  renderType: string | null,
  fontType: string| null,
  status: number
) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/page/publishpages`,

      {
        id,
        bookId,
        pageNo,
        Sequence: pageSequence,
        Content: pageContent,
        type : type || null,
        format : format || null,
        renderType : renderType || null,
        fontType : fontType || null,
        status
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);

    throw error;
  }
};


export const getTypeApi = async (type: string,url:string):Promise<any> => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.get(
      `${url}/taxonomy/get?type=${type}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};



export const deletePageApi = async (id: string) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/page/delete`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting page:", error);
    throw error;
  }
};
