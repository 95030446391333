import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const apiUrl = process.env.REACT_APP_API_URL + "/api";

export const createFestivalApi = async(
    LanguageId: string,
    Name: string,
    Type: number,
    Day: number,
    Month: number,
 
) => {
    const token = cookies.get("authToken");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/festival/create`,
      {
        LanguageId,
        Name,
        Type,
        Day,
        Month,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
}


    export const GetAllFestival = async () => {
      const token = cookies.get("authToken");
      console.log("Token being sent:", token); 
      
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/festival/getallfestival`, 
          { headers: { Authorization: `Bearer ${token}` } } 
        );
        // console.log("Fetched Festival Data:", response.data); 
        return response.data; 
      } catch (error) {
        console.error("Error fetching festivals:", error); 
        return false; 
      }
    };

   
    

    export const deleteFestivalApi = async (id: string) => {
      const token = cookies.get("authToken");
    
      try {
        const response = await axios.delete(`${apiUrl}/festival/deletebyid`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            id,
          },
        });
    
        return response.data;
      } catch (error) {
        console.error("Error deleting festival:", error);
        throw error;
      }
    };

    
export const editFestivalApi = async (
  id: string,
  languageId: string,
  name: string,
  type: number,                                                                
  day: number,
  month: number,
) => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.post(
      `${apiUrl}/festival/editfestival`,
      {
        id: id,
        languageId: languageId,
        name: name,
        type: type,
        day: day,
        month: month,
       
   
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("edit data by id", response.data);
    return response.data;
  } catch (error) {
    console.error("Error editing festival:", error);
    throw error;
  }
};


export const fetchFestivalByIdApi = async (Id: string) => {
  const token = cookies.get("authToken");

  try {
    const response = await axios.get(
      `${apiUrl}/festival/getbyid`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          Id,
        },
      }
    );
    // console.log("edit data by id", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching festivals by ID:", error);
    return false;
  }
};