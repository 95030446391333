import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import {
  FetchLanguagesApi,
  deleteLanguageApi,
  publishLanguagesApi,
} from "../../api/Language";
import LanguageAddForm from "./LanguageAddForm";
import { FaPlus } from "react-icons/fa";

import DeleteItem from "./DeleteItem";
import { toast } from "react-toastify";

const Languages = () => {
  const [languages, setLanguages] = useState<any[]>([]);
  const [isFormVisible, setIsFormVisible] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const navigate = useNavigate();
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<{ id: string } | null>(null);

  useEffect(() => {
    if (location.pathname === "/admin/languages") {
      setIsFormVisible(false);
    }
  }, [location]);

  const handleAddClick = () => {
    setIsFormVisible(true);
    navigate("/admin/languages/addform", {
      state: {
        isEditMode: false,
      },
    });
  };
  const handleEditClick = (id: string) => {
    navigate("/admin/languages/addform", { state: { id } });
  };

  const fetchLanguagesData = useCallback(async () => {
    try {
      const data = await FetchLanguagesApi();
      setLanguages(data);
    }
    catch (error) {
      console.error("Failed to fetch language details");
    }
  }, [])

  useEffect(() => {
    fetchLanguagesData();
  }, [location, fetchLanguagesData]);

  const handleDeleteClick = (id: string) => {
    setSelectedItem({ id });
    setIsModalOpen(true);
  };

  const confirmDelete = async () => {
    if (!selectedItem) return;

    try {
      await deleteLanguageApi(selectedItem.id);
      toast.success("Language deleted successfully");

      fetchLanguagesData();
    } catch (error) {
      toast.error("Error deleting language:");
    } finally {
      setIsModalOpen(false);
      setSelectedItem(null);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentLanguages = languages.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(languages.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const clickToPublishLanguage = useCallback(
    async (
      languageId: string,
      name: string,
      code: string,
      nativeName: string,
      direction: string,
      status: number,
      isDeleted : boolean
    ) => {
      // Mapping status to messages
      const statusMessages: Record<number, string> = {
        1: "review",
        2: "approve",
        3: "publish",
      };
  
      try {
        await publishLanguagesApi(languageId, name, code, nativeName, direction, status, isDeleted);
        toast.success(`Language ${name} sent for ${statusMessages[status]} successfully`);
        await fetchLanguagesData();
      } catch (error) {
        toast.error(`Failed to send language ${name} for ${statusMessages[status]}`);
      }
    },
    [ fetchLanguagesData ]
  );
  
  return (
    <div className="w-full	">
      <div className=" container px-4 mx-auto mt-6 ">
        {isFormVisible ? (
          <LanguageAddForm />
        ) : (
          <>
            {location.pathname === "/admin/languages" && (
              <>
                <div className="w-3/4">
                  <h1 className="text-3xl font-bold text-blue-600 mb-5">
                    Languages
                  </h1>
                </div>
                <div className="flex-grow ml-0 w-full mt-10">
                  <form className="w-full ">
                    <label
                      htmlFor="default-search"
                      className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                    >
                      Search
                    </label>
                    <div className="relative w-full">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg
                          className="w-4 h-4 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      <input
                        type="search"
                        id="default-search"
                        className="inline-block min-w-full p-3 ps-10 outline-none text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search Languages"
                        required
                      />
                      <button
                        type="submit"
                        className="text-white absolute end-1.5 bottom-1.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>

                <div className="flex-grow ml-0 mt-4 w-full">
                  <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg mt-6">
                    <table className=" min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead className="bg-gray-50 dark:bg-gray-800">
                        <tr>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Edit/Delete
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Names
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Codes
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Native Names
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Directions
                          </th>

                          <th
                            scope="col"
                            className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Publish
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                        {currentLanguages.map((language) => (
                          <tr key={language.id}>
                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                              <div className="flex items-center gap-x-6">
                                <button
                                  onClick={() => handleEditClick(language.id)}
                                  className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                    />
                                  </svg>
                                </button>

                                <button
                                  onClick={() => handleDeleteClick(language.id)}
                                  className="text-gray-500 transition-colors duration-200 dark:hover:text-red-600 dark:text-gray-300 hover:text-red-500 focus:outline-none"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </td>

                            <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                              <div className="inline-flex items-center gap-x-3">
                                <div className="flex items-center gap-x-2">
                                  <div>
                                    <h2 className="  text-sm font-medium text-gray-800 dark:text-white ">
                                      {language.name}
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {language.code}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {language.nativeName}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
                                <span className="h-1.5 w-1.5 rounded-full bg-emerald-500"></span>

                                <h2 className="text-sm font-normal text-emerald-500">
                                  {language.status}
                                </h2>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {language.direction === "RTL"
                                ? "RTL (Right to Left)"
                                : "LTR (Left to Right)"}
                            </td>
                            <td>
                              {language.status < 5 ? (
                                <button
                                  onClick={() =>
                                    clickToPublishLanguage(
                                      language.id,
                                      language.name,
                                      language.code,
                                      language.nativeName,
                                      language.direction,
                                      language.status,
                                      language.isDeleted
                                    )
                                  }
                                  className=" text-white end-1.5 bottom-1.5 bg-blue-600 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                  {language.status === 1
                                    ? "Send for Review"
                                    : language.status === 2
                                      ? "Send for Approval"
                                      : language.status === 3
                                        ? "Send for Publish"
                                        : ""}
                                </button>
                              ) : (
                                <div className="flex items-center gap-x-2">
                                  <p className="px-3 py-1 text-sm text-blue-500 rounded-full dark:bg-gray-800 bg-blue-100/60">
                                    Published
                                  </p>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="flex items-center justify-between mt-6">
                    <button
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                      className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-5 h-5 rtl:-scale-x-100"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                        />
                      </svg>

                      <span>previous</span>
                    </button>

                    <div className="items-center hidden lg:flex gap-x-3">
                      {Array.from({ length: totalPages }, (_, index) => (
                        <button
                          key={index + 1}
                          onClick={() => handlePageClick(index + 1)}
                          className={`px-2 py-1 text-sm ${currentPage === index + 1
                            ? "text-blue-500 bg-blue-100"
                            : "text-gray-500 hover:bg-gray-100"
                            } rounded-md`}
                        >
                          {index + 1}
                        </button>
                      ))}
                    </div>

                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                      className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
                    >
                      <span>Next</span>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-5 h-5 rtl:-scale-x-100"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="flex-grow ml-0 mt-4 w-0">
                    <Link to="/admin/languages/addform">
                      <button
                        onClick={handleAddClick}
                        className="flex items-center justify-center px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                      >
                        <FaPlus className="mr-2 font-medium" />
                        Language
                      </button>
                    </Link>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      {isModalOpen && (
        <DeleteItem onClose={() => setIsModalOpen(false)} onConfirm={confirmDelete} />
      )}
    </div>
  );
};

export default Languages;
