import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  Header,
  Login,
  Users,
  UserForm,
  Taxionomies,
  TaxonomyForm,
  Template,
  TemplateForm,
  Footer,
  Privacy,
  TermsAndC,
} from "@11solutionspvtltd/eleven-solutions-common-website";
import Home from "../Components/home/Home";
import About from "../Components/about/About";
import AdminSideBar from "../Components/admin/AdminSideBar";
import Contact from "../Components/contacts/Contact";
import Languages from "../Components/admin/Languages";
import LanguageAddForm from "../Components/admin/LanguageAddForm";
import Pages from "../Components/admin/pages/Pages";
import Category from "../Components/admin/Category";
import CategoryForm from "../Components/admin/CategoryForm";
import AuthorForm from "../Components/admin/AuthorForm";
import Author from "../Components/admin/Author";
import Books from "../Components/admin/Books";
import BooksForm from "../Components/admin/BooksForm";
import FormPages from "../Components/admin/pages/FormPages";
import SectionPage from "../Components/admin/sections/SectionPage";
import SectionPageForm from "../Components/admin/sections/SectionPageForm";
import { ThemeContext } from "../Components/theme/ThemeProvider";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../Components/hooks/redux/redux";

import Cookies from "universal-cookie";
import BookRoles from "../Components/admin/BookRoles";
import BookRolesForm from "../Components/admin/BookRolesForm";
import BookAccess from "../Components/admin/BookAccess";
import BookAccessForm from "../Components/admin/BookAccessForm";
import { setUserProfile } from "../Components/hooks/redux/slices/userProfileSlice";
import { getProfileApi } from "../utils/Api";
import barelviLogo from "../images/BarelviLogo.png";
import Aya from "../Components/admin/Aya";
import { AyaForm } from "../Components/admin/AyaForm";
import Festival from "../Components/admin/Festivals";
import FestivalForm from "../Components/admin/FestivalForm";
const clientId = process.env.REACT_APP_GOOGLE_CLIENTID as string;
function AppRouter() {
  const location = useLocation();
  const userProfile = useAppSelector((state) => state.userProfile.userData);
  // const { user, logout } = useAuth();
  const [userType, setUserType] = useState<number>();
  const isAdminRoute = location.pathname.startsWith("/admin");
  const [iscloseModal, setclosemodal] = useState(true);
  const themeContext = useContext(ThemeContext);
  const isDarkMode = themeContext?.isDarkMode ?? false;
  const toggleTheme = themeContext?.toggleTheme ?? (() => {});
  const cookies = new Cookies();
  const [userLogedin, setUserLogedin] = useState(false);
  const token = cookies.get("authToken");
  const [ModalOpen] = useState(true);
  const url: string = process.env.REACT_APP_API_URL!;
  const [userDetails, setUserDetails] = useState({});
  const navigate = useNavigate();
  const closeModal = () => {
    setclosemodal(!iscloseModal);
    navigate("/home");
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      setUserLogedin(true);
      const fetchProfile = async () => {
        try {
          const actualToken =
            typeof token === "object" && token.data ? token.data.token : token;
          const response = await getProfileApi(actualToken, url);
          setUserDetails(response);
          if (response) {
            dispatch(setUserProfile(response));
          }
        } catch (error) {
          console.error("Error fetching profile:", error);
        }
      };
      fetchProfile();
    }
  }, [token, dispatch, url]);

  useEffect(() => {
    if (userProfile) {
      setUserType(userProfile?.roleType);
    }
  }, [userProfile]);

  useEffect(() => {
    if (location.pathname === "/login") {
      if (userProfile) {
        setclosemodal(false);
      } else {
        setclosemodal(true);
      }
    }
  }, [location.pathname, userProfile]);
  return (
    <>
      {!isAdminRoute && (
        <Header
          toggleTheme={toggleTheme}
          isDarkMode={isDarkMode}
          userLogedin={userLogedin}
          userType={userType}
          url={url}
          img={barelviLogo}
          name="Barelvi"
          userdetails={userDetails}
          menugroups
          donationHistory
        />
      )}
      <Routes>
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/login"
          element={
            iscloseModal ? (
              <Login
                closeModal={closeModal}
                isDarkMode={isDarkMode}
                url={url}
                ModalOpen={ModalOpen}
                img={barelviLogo}
              />
            ) : (
              <Home />
            )
          }
        />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/termsandconditions"
          element={<TermsAndC isDarkMode={isDarkMode} />}
        />
        <Route
          path="/privacyandpolicy"
          element={<Privacy isDarkMode={isDarkMode} />}
        />

        {userProfile?.roleType === 2 ? (
          // Admin can access these routes
          <Route path="/admin" element={<AdminSideBar />}>
            <Route path="users" element={<Users url={url} />} />
            <Route path="users/userform" element={<UserForm url={url} />} />

            <Route path="taxinomies" element={<Taxionomies url={url} />} />
            <Route
              path="taxinomies/taxonomyform"
              element={<TaxonomyForm url={url} />}
            />
            <Route path="template" element={<Template url={url} />} />
            <Route
              path="template/templateform"
              element={<TemplateForm url={url} />}
            />

            {/* <Route path="dashboard" element={<Dashboard />} /> */}
            <Route path="languages" element={<Languages />} />
            <Route path="books" element={<Books />} />
            <Route path="books/booksform" element={<BooksForm />} />
            <Route path="languages/addform" element={<LanguageAddForm />} />
            <Route path="category" element={<Category />} />
            <Route path="category/categoryform" element={<CategoryForm />} />
            <Route path="author" element={<Author />} />
            <Route path="author/authorform" element={<AuthorForm />} />
            <Route path="pages" element={<Pages />} />
            <Route path="pages/formpages" element={<FormPages  url={url} />} />
            <Route path="sections" element={<SectionPage />} />
            <Route path="sections/sectionsForm" element={<SectionPageForm />} />
            <Route path="bookroles" element={<BookRoles />} />
            <Route path="bookroles/bookrolesform" element={<BookRolesForm />} />
            <Route path="bookaccess" element={<BookAccess />} />
            <Route
              path="bookaccess/bookaccessform"
              element={<BookAccessForm />}
            />
            <Route path="aya" element={<Aya />} />
            <Route path="ayaform" element={<AyaForm />} />
            <Route path="Festivals" element={<Festival />} />
            <Route path="Festivals/FestivalForm" element={<FestivalForm />} />
          </Route>
        ) : null}
      </Routes>
      {!isAdminRoute && (
        <Footer
          isDarkMode={isDarkMode}
          companyName={"Eleven Software Solutions Pvt Ltd"}
          img={barelviLogo}
          name="Barelvi"
        />
      )}
    </>
  );
}
function Router() {
  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId={clientId}>
        {/* <AuthProvider> */}
        <AppRouter />
        {/* </AuthProvider> */}
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
}
export default Router;
