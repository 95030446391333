import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addAuthorApi,
  editAuthorApi,
  fetchAuthorByIdApi,
} from "../../api/Author";
import { FetchLanguagesApi } from "../../api/Language";
import { toast } from "react-toastify";

const AuthorForm = () => {
  const [authorName, setAuthorName] = useState<string>("");
  const [languages, setLanguages] = useState<any[]>([]);
  const [languageId, setLanguageId] = useState<string>("");
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [authorStatus, setAuthorStatus] = useState<number>(Number);
  const [authorId, setAuthorId] = useState<string>("");
  const [selectedRole, setSelectedRole] = useState<
    "writer" | "recorder" | "translator" | null
  >(null);

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = location.state || {};

  useEffect(() => {
    const fetchAuthorData = async () => {
      if (!id) return;
      try {
        const author = await fetchAuthorByIdApi(id);
        if (author) {
          setAuthorId(author.id);
          setAuthorName(author.name);
          setLanguageId(author.languageId);
          setAuthorStatus(author.status);
          setSelectedRole(
            author.isWriter
              ? "writer"
              : author.isRecorder
              ? "recorder"
              : author.isTranslator
              ? "translator"
              : null
          );
          setIsEditMode(true);
        }
      } catch (error) {
        toast.error("Unable to fetch Author data" + error);
      }
    };
    fetchAuthorData();
  }, [id]);

  useEffect(() => {
    const fetchLanguagesData = async () => {
      try {
        const data = await FetchLanguagesApi();
        setLanguages(data);
      } catch (error) {
        toast.error("Unable to fetch Languages data" + error);
      }
    };
    fetchLanguagesData();
  }, []);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!authorName || !languageId || !selectedRole) {
      toast.error("Please fill all the required fields");
      return;
    }

    try {
      if (isEditMode) {
        await editAuthorApi(
          authorId,
          authorName,
          languageId,
          authorStatus,
          selectedRole
        );
        toast.success("Author updated successfully");
      } else {
        await addAuthorApi(authorName, languageId, selectedRole);
        toast.success("Author added successfully");
      }
      navigate("/admin/Author");
    } catch (error) {
      console.error("Error adding/updating Author:", error);
      toast.error("Failed to add/update Author");
    }
  };

  const handleCancel = () => {
    setAuthorName("");
    setLanguageId("");
    navigate("/admin/Author");
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRole(event.target.value as "writer" | "recorder" | "translator");
  };

  return (
    <div className="max-w-4xl p-6 dark:bg-gray-800">
      <div>
        <form>
          <h1 className="text-3xl font-bold text-blue-600 capitalize dark:text-white mb-4">
            {isEditMode ? "Edit Author" : "Add Author"}
          </h1>

          <div className="mb-6 w-full">
            <label className="text-gray-900 dark:text-gray-200 font-semibold">
              Author Name <span className="text-red-500">*</span>
            </label>
            <input
              required
              type="text"
              name="name"
              value={authorName}
              onChange={(e) => setAuthorName(e.target.value)}
              className="w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              placeholder="Author Name"
            />
          </div>

          <div className="mb-6 w-full">
            <label className="text-gray-900 dark:text-gray-200 font-semibold">
              Language <span className="text-red-500">*</span>
            </label>
            <select
              className="w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              value={languageId}
              onChange={(e) => {
                setLanguageId(e.target.value);
              }}
            >
              <option value="">Select a language</option>
              {languages.length > 0 ? (
                languages.map((language) => (
                  <option key={language.id} value={language.id}>
                    {language.name}
                  </option>
                ))
              ) : (
                <option value="">Loading languages...</option>
              )}
            </select>
          </div>
          <div className="mb-6 w-full">
            <label className="text-gray-900 dark:text-gray-200 font-semibold">
              Role <span className="text-red-500">*</span>
            </label>
            <select
              value={selectedRole || ""}
              onChange={handleRoleChange}
              className="w-full px-4 py-2 mt-2  text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            >
              <option value="">Select a Role</option>
              <option value="writer">Writer</option>
              <option value="recorder">Recorder</option>
              <option value="translator">Translator</option>
            </select>
          </div>

          <div className="flex space-x-4 mt-6 ">
            <button
              onClick={handleSubmit}
              type="submit"
              className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            >
              Save And Close
            </button>
            <button
              type="button"
              onClick={handleCancel}
              className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AuthorForm;
