import axios from "axios";
import Cookies from "universal-cookie";

export const registerApi = async (
  name: string,
  email: string,
  mobile: string,
  password: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/login/Create`,
      {
        LoginType: 1,
        name,
        email,
        mobile,
        password,
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(
        "API Error:",
        error.response ? error.response.data : error.message
      );
    } else {
      console.error("Unexpected Error:", error);
    }
    return false;
  }
};

export const loginApi = async (email: string, password: string) => {
  // const cookies = new Cookies();
  //   const token = cookies.get("token");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/login`,
      {
        Type: 1,
        email,
        password,
      }
    );

    if (response.status === 200 && response.data?.token) {
      console.log(response.data);
      return response;
    } else {
      return false;
    }
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    } else {
      console.error("Login error:", error);
      return false;
    }
  }
};
export const getProfileApi = async (token: string, url: string) => {
  try {
    const response = await axios.get(`${url}/login/get`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return response.data;
  } catch (error: any) {
    console.error(error);
    return false;
  }
};

export const getAllUsersApi = async (searchTerm: string) => {
  const token = cookies.get("authToken");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/login/getuser`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          searchTerm: searchTerm,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error(error);
    return false;
  }
};

export const updateProfileApi = async (
  token: string,
  name: string,
  mobile: string,
  address: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/login/update`,
      {
        name,
        mobile,
        address,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error during API call:", error);
    throw error;
  }
};

export const donateApi = async (
  amount: number,
  details: any[],
  token: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/donation/create`,
      {
        amount,
        donationDetails: details,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const donateHistoryApi = async (token: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/donation/get`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);

    return false;
  }
};

export const addExpenseApi = async (
  Type: number,
  Purpose: number,
  Amount: number,
  Desc: string
) => {
  const token = cookies.get("token");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/expense/create`,
      {
        Type,
        Purpose,
        Amount,
        Desc,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

const cookies = new Cookies();
export const getExpenseApi = async () => {
  const token = cookies.get("token");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/expense/get`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateExpenseApi = async (
  userId: string,
  Purpose: number,
  Type: number,
  Desc: string
) => {
  const token = cookies.get("token");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/expense/update`,
      {
        userId,
        Purpose,
        Type,
        Desc,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};
export const addSection = async (
  bookId: any,
  name: any,
  parentId: any,
  pageId: any,
  isDeleted: any
) => {
  const token = cookies.get("token");

  // Log the data being sent to the API
  console.log("API Payload:", {
    BookId: bookId,
    Name: name,
    ParentId: parentId,
    PageId: pageId,
    IsDeleted: isDeleted,
  });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/section/add`,
      {
        BookId: bookId,
        Name: name,
        ParentId: parentId || null,
        PageId: pageId,
        IsDeleted: isDeleted,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};
export const getSection = async () => {
  const token = cookies.get("token");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/section/getsections`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // console.log(response, "this is response");

    if (response.data) {
      // console.log("Data received:", response.data);
      return response.data;
    } else {
      console.log("No data received.");
    }

    return [];
  } catch (error) {
    console.error("Error fetching pages:", error);
    throw error;
  }
};
export const getBooks = async () => {
  const token = cookies.get("token");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/book/getbooks`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log(response, "this is response");

    if (response.data) {
      console.log("Data received:", response.data);
      return response.data;
    } else {
      console.log("No data received.");
    }

    return [];
  } catch (error) {
    console.error("Error fetching pages:", error);
    throw error;
  }
};
export const editSec = async (
  isDeleted: any,
  secId: any,
  name: any,
  pageId: any
) => {
  const token = cookies.get("token");
  console.log(secId);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/section/update`,
      {
        secId: secId,
        Name: name,
        // ParentId: parentId || null,
        PageId: pageId,
        IsDeleted: isDeleted,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log(response, "this is edit section");

    if (response.data) {
      console.log("Data received:", response.data);
      return response.data;
    } else {
      console.log("No data received.");
    }

    return [];
  } catch (error) {
    console.error("Error updating section:", error); // Changed message for clarity
    throw error;
  }
};

export const AddpageApi = async (
  pageNo: string,
  sequence: number,
  content: string,
  bookId: string
) => {
  const token = cookies.get("token");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/page/add`,
      {
        pageNo,
        sequence,
        content,
        bookId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding page:", error);
  }
};

export const FetchpageApi = async () => {
  const token = cookies.get("token");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/page/getpage`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching pages:", error);
  }
};

export const EditPageApi = async (
  id: string | null,
  bookId: string | null,
  PageNo: string,
  sequence: number,
  content: string
): Promise<any> => {
  const token = cookies.get("token");

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/page/edit`,
      {
        editPage: "Your edit page value here",
        id: id || undefined,
        bookId: bookId || undefined,
        PageNo: PageNo,
        sequence: Number(sequence),
        content: content.trim(),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error editing Pages:", error.response?.data);
      console.error("Error status:", error.response?.status);
    } else {
      console.error("Unexpected error:", error);
    }
    throw error;
  }
};
