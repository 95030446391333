import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllAyaData } from "../../api/Aya";
import toast from "react-hot-toast";

interface Ayas {
  id: number;
  ar: string;
  num: number;
  engtext: string;
  urdutext: string;
  tafseer: string;
  pagenum: number;
  surahid: number;
  paraid: number;
  surahName: string;
  paraName: string;
}

const Aya = () => {
  const [aya, setAya] = useState<Ayas[]>([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterModal, setfilterModal] = useState(false);
  const [surahOptions, setSurahOptions] = useState<
    { id: number; name: string }[]
  >([]);

  const [paraOptions, setParaOptions] = useState<
    { id: number; name: string }[]
  >([]);
  const [selectedSurah, setSelectedSurah] = useState<number | null>(null);
  const [selectedPara, setSelectedPara] = useState<number | null>(null);

  const navigate = useNavigate();
  const itemsPerPage = 10;
  const maxPageButtons = 10;

  const fetchAyasData = async (pageNumber: number) => {
    try {
      const ayaData = await getAllAyaData(
        pageNumber,
        itemsPerPage,
        selectedSurah ?? 0,
        selectedPara ?? 0
      );

      if (ayaData && Array.isArray(ayaData.ayas)) {
        setAya(ayaData.ayas);
        const totalCount = ayaData.totalCount || 0;
        setTotalPages(Math.ceil(totalCount / itemsPerPage));
      } else {
        setAya([]);
      }
    } catch (error) {
      toast.error("Failed to fetch Aya data.");
    }
  };

  useEffect(() => {
    fetchAyasData(currentPage);
  }, [currentPage, selectedSurah, selectedPara]);

  const handleEditClick = (id: number) => {
    navigate("/admin/ayaform", { state: { id } });
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => {
      if (prevPage < totalPages) {
        return prevPage + 1;
      }
      return prevPage;
    });
  };

  const getPageNumbers = () => {
    const pages = [];
    if (totalPages <= maxPageButtons) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (currentPage <= 3) {
      pages.push(1, 2, 3, "...", totalPages);
    } else if (currentPage >= totalPages - 2) {
      pages.push(1, "...", totalPages - 2, totalPages - 1, totalPages);
    } else {
      pages.push(
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        totalPages
      );
    }
    return pages;
  };

  const fetchFilterOptions = async () => {
    try {
      const ayaData = await getAllAyaData(
        1,
        1000,
        Number(selectedSurah),
        Number(selectedPara)
      );

      if (ayaData && Array.isArray(ayaData.ayas)) {
        const uniqueSurahs: any = Array.from(
          new Set(ayaData.ayas.map((a: any) => a.surahName))
        ).map((name) => ({
          id: ayaData.ayas.find((a: any) => a.surahName === name)?.surahId || 0,
          name,
        }));

        const uniqueParas: any = Array.from(
          new Set(ayaData.ayas.map((a: any) => a.paraName))
        ).map((name) => ({
          id: ayaData.ayas.find((a: any) => a.paraName === name)?.paraId || 0,
          name,
        }));

        setSurahOptions(uniqueSurahs);
        setParaOptions(uniqueParas);
      }
    } catch (error) {
      toast.error("Failed to fetch filter options.");
    }
  };
  useEffect(() => {
    if (filterModal) {
      fetchFilterOptions();
    }
  }, [filterModal]);

  const resetFields = () => {
    setSelectedSurah(null);
    setSelectedPara(null);
    fetchAyasData(1);
    fetchFilterOptions();
  };

  return (
    <div className="container px-4 mx-auto">
      <h2 className="text-3xl font-bold  text-blue-600 mt-6 ">Aya Details</h2>
      <div className="flex-grow ml-0 w-full mt-10 flex flex-row">
        <form className="w-full ">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="inline-block min-w-full p-3 ps-10 outline-none text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search Aya's"
              required
              onChange={(e) => setSearch(e.target.value)}
            />
            <button
              type="submit"
              className="text-white absolute end-1.5 bottom-1.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Search
            </button>
          </div>
        </form>
        <button
          onClick={() => setfilterModal(true)}
          className="text-white ml-2 bottom-1.5 bg-blue-700 hover:bg-blue-800 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-1 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          aria-label="Toggle Menu"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icon icon-tabler icons-tabler-outline icon-tabler-filter"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z" />
          </svg>
        </button>
      </div>

      <div className="flex-grow ml-0 mt-6 w-full">
        <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-800">
              <tr>
                <th
                  scope="col"
                  className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  Edit
                </th>
                <th
                  scope="col"
                  className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  <span>Surah</span>
                </th>
                <th
                  scope="col"
                  className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  <span>Para</span>
                </th>
                <th
                  scope="col"
                  className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  <span>Ayat Number</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
              {aya.map((aya: any) => (
                <tr key={aya.id}>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    <div className="flex items-center gap-x-6">
                      <button
                        onClick={() => handleEditClick(aya.id)}
                        className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                          />
                        </svg>
                      </button>
                    </div>
                  </td>

                  <td className="px-4 py-4 font-medium text-gray-800 dark:text-gray-300 whitespace-nowrap">
                    {aya.surahName}
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                    <div className="flex items-center gap-x-2">
                      <p className="px-3 py-1 text-sm text-indigo-500 rounded-full dark:bg-gray-800 bg-indigo-100/70">
                        {aya.paraName}
                      </p>
                    </div>
                  </td>

                  <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                    {aya.num}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* filter model */}
      <div className="inset-0 bg-gray-800 bg-opacity-50 z-50 transition-opacity duration-300">
        <div
          className={`fixed top-0 right-0 h-full w-[300px] md:w-[500px] bg-white p-4 rounded-md shadow-lg transform transition-transform duration-500 ease-in-out
          ${filterModal ? "translate-x-0" : "translate-x-full"}`}
        >
          <h1 className="text-2xl md:text-3xl font-bold text-blue-600 mb-4 md:mb-6">
            Filter
          </h1>
          <button
            onClick={() => setfilterModal(false)}
            className="absolute top-1 right-3 text-gray-400 hover:text-gray-600 text-2xl"
          >
            &times;
          </button>
          <div className="flex flex-col">
            <div className="mb-4 flex flex-col md:flex-row md:justify-between">
              <label className="block text-gray-700 mt-2 mr-0 md:mr-5">
                Surah
              </label>
              <div className="w-full mt-2 md:mt-0">
                <select
                  value={selectedSurah ?? ""}
                  onChange={(e) => setSelectedSurah(Number(e.target.value))}
                  className="w-full mt-2 md:mt-0 px-3 py-2 border rounded-md"
                >
                  <option value="">Select Surah</option>
                  {surahOptions.map((surah) => (
                    <option key={surah.id} value={surah.id}>
                      {surah.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mb-4 flex flex-col md:flex-row md:justify-between">
              <label className="block text-gray-700 mt-2 mr-0 md:mr-5">
                Para
              </label>
              <div className="w-full mt-2 md:mt-0">
                <select
                  value={selectedPara ?? ""}
                  onChange={(e) => setSelectedPara(Number(e.target.value))}
                  className="w-full mt-2 md:mt-0 px-3 py-2 border rounded-md"
                >
                  <option value="">Select Para</option>
                  {paraOptions.map((para) => (
                    <option key={para.id} value={para.id}>
                      {para.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-row gap-3">
              <button
                onClick={resetFields}
                className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Pagination */}
      <div className="flex flex-wrap items-center justify-center md:justify-between mt-6 gap-2 md:gap-4">
        {/* Previous Button */}
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
          className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5 rtl:-scale-x-100"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
            />
          </svg>
          <span>Previous</span>
        </button>

        {/* Page Number */}
        <div className="flex flex-wrap items-center justify-center gap-x-2">
          {getPageNumbers().map((page, index) => (
            <button
              key={index}
              onClick={() => typeof page === "number" && setCurrentPage(page)}
              className={`px-2 py-1 text-sm ${
                currentPage === page
                  ? "text-blue-500 bg-blue-100"
                  : "text-gray-500 hover:bg-gray-100"
              } rounded-md`}
              disabled={typeof page !== "number"}
            >
              {page}
            </button>
          ))}
        </div>

        {/* NEXT Button */}
        <button
          onClick={handleNextClick}
          className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800"
        >
          <span>Next</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5 rtl:-scale-x-100"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Aya;
