import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllBookRoleApi } from "../../api/BookRole";
import { getAllUsersApi } from "../../utils/Api";
import { createBookAccessApi} from "../../api/BookAccess";
import { toast } from "react-toastify";

const BookAccessForm = () => {
  const [roleId, setRoleId] = useState<string>("");
  const [roleOptions, setRoleOptions] = useState([]);
  const [searchUserResults, setSearchUserResults] = useState([]);
  const [searchUserName, setSearchUserName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const navigate = useNavigate();
  // const location = useLocation();
  // const { userId } = location.state || {};

  // useEffect(() => {
  //   const fetchBookAccessById = async () => {
  //     if (!userId) return;
  //     try {
  //       const bookAccessToEdit = await getBookAccessByIdAPi(userId);
  //       if (bookAccessToEdit) {
  //         setRoleId(bookAccessToEdit.roleId);
  //         setSelectedUserId(bookAccessToEdit.userId);
  //         setSearchUserName(bookAccessToEdit.userName);
  //         setIsEditMode(true);
  //       }
  //     }
  //     catch (error) {
  //       console.error("no data is fetching to edit book access");
  //     }
  //   }
  //   fetchBookAccessById();
  // }, [userId])

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [roleRes,] = await Promise.all([
          getAllBookRoleApi(),
        ]);
        setRoleOptions(roleRes);
      } catch (error) {
        console.error("Error fetching dropdown options", error);
      }
    };

    fetchOptions();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchUserName(e.target.value);
    setIsOpen(true);
  };

  const handleOptionClick = (userId: string, userName: string) => {
    setSelectedUserId(userId); 
    setSearchUserName(userName); 
    setIsOpen(false);
  };

  const fetchUserData = async (searchTerm: string) => {
    try {
      const response = await getAllUsersApi(searchTerm);
      setSearchUserResults(response);
    } catch (error) {
      console.error("Not Able To Fetch user name", error);
    }
  };

  useEffect(() => {
    if (!searchUserName.trim()) {
      setSearchUserResults([]);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      fetchUserData(searchUserName);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchUserName]);


  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!roleId || !selectedUserId) {
      toast.error("Please fill all the required fields");
      return;
    }
    try {
        await createBookAccessApi(roleId, selectedUserId);
        toast.success("New book Role Added Succesfully");
        navigate("/admin/bookaccess");
      }
       catch (error) {
      console.error("Unable to add", error);
    }
  };


  return (
    <section className="max-w-3xl mx-auto p-6  dark:bg-gray-800">
      <h2 className="text-3xl font-bold text-center text-blue-600 capitalize dark:text-white">
        Add book Role Access
      </h2>
      <form>
        <div className="grid grid-rows-2 gap-6 mt-4 sm:grid-rows-2">
          <div className="relative">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              User Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className="w-full border border-gray-300 p-2 rounded-md shadow-md placeholder-gray-500"
              placeholder="Type to search user..."
              value={searchUserName}
              onChange={handleInputChange}
              onFocus={() => setIsOpen(true)}
            />
            {isOpen && searchUserResults.length > 0 && (
              <ul className="absolute mt-1 w-full max-h-60 overflow-auto bg-white border border-gray-300 rounded-md shadow-lg z-10">
                {searchUserResults.map((user: any) => (
                  <li
                    key={user.id}
                    onClick={() => handleOptionClick(user.id, user.name)}
                    className="cursor-pointer select-none py-2 px-4 hover:bg-blue-500 hover:text-white"
                  >
                    {user.name}
                  </li>
                ))}
              </ul>
            )}
            {isOpen && searchUserResults.length === 0 && (
              <ul className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
                <li className="cursor-default select-none py-2 px-4 text-gray-500">
                  No data found
                </li>
              </ul>
            )}
          </div>

          <div>
            <label
              className="text-gray-900 dark:text-gray-200 font-semibold"
              htmlFor="password"
            >
              Role <span className="text-red-500">*</span>
            </label>
            <select
              required
              value={roleId}
              onChange={(event) => setRoleId(event.target.value)}
              className=" w-full px-4 py-2 mt-2 text-gray-800  bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            >
              <option value="">select role to assign user</option>
              {roleOptions.length > 0 &&
                roleOptions.map((option: any) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="flex justify-start gap-4 mt-6">
          <button
            className="px-8 py-2.5 leading-5 text-white font-semibold transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            onClick={handleSubmit}
          >
            Add
          </button>
          <button
            className="px-8 py-2.5 leading-5 text-white font-semibold transition-colors duration-300 transform  bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
            onClick={() => navigate("/admin/bookaccess")}
          >
            Cancel
          </button>
        </div>
      </form>
    </section>
  );
};

export default BookAccessForm;
